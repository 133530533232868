<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <v-card>
      <v-card-title
        >Sell {{ $_getLocale() == "zh" ? "" : " "
        }}{{
          $t("model.name." + this.model.name.singular.toLowerCase())
        }}</v-card-title
      >
      <v-card-text>
        <!-- BOC:[alert] -->
        <v-alert border="bottom" colored-border type="primary" elevation="2"
          ><div class="d-flex">
            <span>
              Are you sure you want to sell 
            </span>
            <b class="ml-2">{{ me.Item.name? me.Item.name: `${model.name.singular} ${$route.params.id}` }} to {{ me.Item.price }}</b>
              <span class="ml-1">
                  <v-img
                    contain
                    max-height="20"
                    max-width="20"
                    :src="me.Item.Currency.images.icon.url"
                  ></v-img>
                </span> 
                <b>?</b>
          </div>
        </v-alert>
        <!-- EOC -->
        <!-- BOC:[form] -->
        <div class="text-right">
          <v-btn color="primary" class="mr-4" @click="submit" text>
            {{ $t("action.yes") }}
          </v-btn>
          <v-btn text @click="$router.go(-1)">
            {{ $t("action.no") }}
          </v-btn>
        </div>
        <!-- EOC -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
//BOC:[model]
import modelParent from "@/models/items/player";
import model from "@/models/items/inventory";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  props: ["parent", "self"],
  data: () => ({
    //BOC:[role]
    role: "Moderator",
    //EOC
    //BOC:[model]
    modelParent: modelParent,
    model: model,
    //EOC
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[parent]
    myParent: {
      id: null,
    },
    //EOC
    //BOC:[self]
    me: {
      id: null,
    },
    //EOC
    //BOC:[form]
    form: {},
    //EOC
  }),
  created() {
    //BOC:[guard]
    if (!this.model[this.role].delete) {
      this.$router.replace(
        this.$_getRouteRead(
          this.role,
          this.model.key,
          this.$route.params.id,
          this.self
        )
      );
      return;
    }
    //EOC
    //BOC:[parent]
    if (this.parent) this.myParent = this.$_.cloneDeep(this.parent);
    //EOC
    //BOC:[self]
    if (this.self) this.me = this.$_.cloneDeep(this.self);
    //EOC
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: this.$t("model.name.dashboard"),
      to: { name: "Page" + this.role + "Dashboard" },
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text: this.$t("model.name." + this.modelParent.name.plural.toLowerCase()),
      to: this.$_getRouteBrowse(this.role, this.modelParent.key),
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text:
        this.myParent && this.myParent.name
          ? this.myParent.name
          : `${this.$t(
              "model.name." + this.modelParent.name.singular.toLowerCase()
            )} ${this.$route.params.parentId}`,
      to: this.$_getRouteRead(
        this.role,
        this.modelParent.key,
        this.$route.params.parentId,
        this.myParent
      ),
      exact: true,
    });
    //
    let path = this.$_.cloneDeep(
      this.breadcrumbs[this.breadcrumbs.length - 1].to
    );
    path.query = { tab: "Inventory" };
    this.breadcrumbs.push({
      text: this.$t("model.name." + this.model.name.plural.toLowerCase()),
      to: path,
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text: this.me.Item.name
        ? this.me.Item.name
        : `${this.$t("model.name." + this.model.name.singular.toLowerCase())} ${
            this.$route.params.id
          }`,
      to: this.$_getRouteChildRead(
        this.role,
        this.model.key,
        this.$route.params.id,
        this.self,
        this.modelParent.key,
        this.$route.params.parentId,
        this.myParent
      ),
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text: "Sell",
      to: this.$_getRouteChildRestore(
        this.role,
        this.model.key,
        this.$route.params.id,
        this.self,
        this.modelParent.key,
        this.$route.params.parentId,
        this.myParent
      ),
      exact: false,
    });
    //EOC
    //BOC:[url]
    this.api.url = `${this.$api.servers.game}/api/v1/en/moderator/player/${this.$route.params.parentId}/inventory/reverse`;

    //EOC
    //BOC:[api]
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = () => {
      this.api.isLoading = false;
      let redirect = this.$_getRouteRead(
        this.role,
        this.modelParent.key,
        this.$route.params.parentId,
        this.myParent
      );
      redirect.query = { tab: this.model.key };
      this.$router.push(redirect);
    };
    //EOC
    //BOC:[form]
    this.form.id = this.$route.params.id;
    this.form.quantity = 1;
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //BOC:[form]
    submit() {
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
    //EOC
  },
};
</script>